import "./initializers";
import "./components";
import "./vue";
import "./custom";

$(".attendance-other").click(function() {
  $(".attendance-other-detail").toggle(this.checked);
});

$(".attendance-third").click(function() {
  $(".attendance-third-detail").toggle(this.checked);
});

$(".attendance-oppose-commercial-prospecting").click(function() {
  $(".attendance-oppose-commercial-prospecting-detail").toggle(this.checked);
});

$(".attendance-correct-data").click(function() {
  $(".attendance-correct-data-detail").toggle(this.checked);
});

$(".attendance-oppose-treatment").click(function() {
  $(".attendance-oppose-treatment-detail").toggle(this.checked);
});

$(".attendance-other-rights").click(function() {
  $(".attendance-other-rights-detail").toggle(this.checked);
});
